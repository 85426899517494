body{
    height: 100vh;
    margin: 0;
    font-family: 'Source Sans Pro', sans-serif;
    scroll-behavior: smooth;
}

#headerContainer{

    /* Background Gradient */
    background-size: auto, 150%;
    background: linear-gradient(
            rgba(0, 0, 0, 0.3),
            rgba(0, 0, 0, 0.3)
    ) no-repeat;

    background-image: -webkit-linear-gradient(#3e4d56 0%, #71675b 45%, #231d1a 100%);
    background-image: -moz-linear-gradient(#3e4d56 0%, #71675b 45%, #231d1a 100%);
    background-image: -o-linear-gradient(#3e4d56 0%, #71675b 45%, #231d1a 100%);
    background-image: linear-gradient(#3e4d56 0%, #71675b 45%, #231d1a 100%);
}

header{
    text-align: center;
    padding: 20% 10%;
    color: rgb(186, 181, 171);
    line-height: 1.8em;
    font-size: 1.8em;
    font-weight: 500;
}

header img {
    height: 128px;
    width: 128px;
    border-radius: 50%;
}

header strong{
    color: white;
}

header a{
    color: lightgray;
    transition: color 0.25s;
    margin-left: 20px;
}

header a:hover{
    transition: color 0.25s;
    color: turquoise;
}

#appContainer{
    background: #181A1B;
    margin: 0;
    min-height: 100vh;
}

@media only screen and (min-width: 600px) {
    #headerContainer {

        /* Make the header a fixed sidebar. */
        position: fixed;
        top: 0;
        left: 0;
        width: inherit;
        height: 100vh;
    }

    header{
        text-align: right;
    }
}
